import {
	Flex,
	Box,
	Text,
	Link,
	Image,
	Modal,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Button,
	useDisclosure,
	useColorModeValue,
	type ResponsiveValue,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useRecoilValue } from 'recoil'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import { TCShopButton } from './TCShopButton'
import { ShopIcon } from '../../svg'
import { useAppSelector } from '../../../hooks'
import { ModalOverlay } from '../../shared'
import { rtlLocales } from '../../../lang/messages'
import { BITCOIN_ORANGE } from '../../../constants'
import { circlesBreakpointState, confModeState, landscapeOrientationState } from '../../../state'
import { getBreakpointValue } from '../../../utils'

export const TCShop = ({ ...rest }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const confMode = useRecoilValue(confModeState)
	const { userLocale } = useAppSelector((state) => state.settings)
	
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const colorAlt2 = useColorModeValue('rgba(0,0,0,0.3)', 'rgba(255,255,255,0.3)')
	const responsiveDirection = getBreakpointValue({ base: 'column', sm: 'row' }, circlesBreakpoint) as ResponsiveValue<'column' | 'row'>
	const squareImageBoxSize = getBreakpointValue({ base: '160px', sm: '200px' }, circlesBreakpoint)
	const shirtImageBoxSize = landscapeOrientation
		? getBreakpointValue({ base: '160px', sm: '200px' }, circlesBreakpoint)
		: getBreakpointValue({ base: '160px', md: '200px' }, circlesBreakpoint)
	const shirtImageMaxW = landscapeOrientation
		? getBreakpointValue({ base: '128px', sm: '160px' }, circlesBreakpoint)
		: getBreakpointValue({ base: '128px', md: '160px' }, circlesBreakpoint)

	const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined
	const interactionStyles = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}

	return (
		<>
			{!confMode && (
				<TCShopButton
					onClick={onOpen}
					{...rest}
				/>
			)}

			<Modal
				key="shop"
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				motionPreset="slideInBottom"
				size="2xl"
			>
				<ModalOverlay />

				<ModalContent
					bg={bg}
					borderWidth={1}
					borderColor={color}
					color={color}
					borderRadius="lg"
					zIndex={3334}
					dir={direction}
				>
					<ModalHeader pb={0} mb={4}>
						<Flex align="center" gap="1">
							<ShopIcon width={20} color={colorAlt} />

							<Text
								color={colorAlt}
								fontSize="xs"
								textTransform="uppercase"
							>
								<FormattedMessage id="info.panel_label.shop" />
							</Text>
						</Flex>
					</ModalHeader>

					<ModalBody
						maxH="600px"
						overflowY="auto"
					>
						<Flex
							direction="column"
							align="center"
							justify="space-around"
							gap={10}
						>
							<Flex
								w="100%"
								gap={5}
								direction={responsiveDirection}
							>
								<Link
									flex="1 1 33.33%"
									role="group"
									color={color}
									fontWeight="semibold"
									href="https://proofofink.com/product/timechain-calendar"
									display="flex"
									flexDirection="column"
									alignItems="center"
									isExternal
									_hover={{
										color: BITCOIN_ORANGE,
									}}
								>
									<Box
										bg={colorAlt2}
										borderRadius={8}
										p="2px"
										mb={1}
										_groupHover={{
											bg: BITCOIN_ORANGE
										}}
									>
										<Image
											src="/shop/POI-black-tshirt.jpeg"
											boxSize={shirtImageBoxSize}
											maxW={shirtImageMaxW}
											objectFit="contain"
											borderRadius={8}
										/>
									</Box>
									
									<Text fontSize="sm" textAlign="center">
										Logo T-shirt<br />Proof of Ink <ExternalLinkIcon
											boxSize={{ base: 3, md: 4 }}
											color={colorAlt}
											_groupHover={{
												color: BITCOIN_ORANGE
											}}
										/><br /><span style={{ color: colorAlt }}>(Made in USA)</span>
									</Text>
								</Link>

								<Link
									flex="1 1 33.33%"
									role="group"
									color={color}
									fontWeight="semibold"
									href="https://proofofink.com/product/timechain-calendar-hoodie"
									display="flex"
									flexDirection="column"
									alignItems="center"
									isExternal
									_hover={{
										color: BITCOIN_ORANGE,
									}}
								>
									<Box
										bg={colorAlt2}
										borderRadius={8}
										p="2px"
										mb={1}
										_groupHover={{
											bg: BITCOIN_ORANGE
										}}
									>
										<Image
											src="/shop/POI-black-hoodie.jpg"
											boxSize={shirtImageBoxSize}
											maxW={shirtImageMaxW}
											objectFit="contain"
											borderRadius={8}
										/>
									</Box>
									
									<Text fontSize="sm" textAlign="center">
										Logo Hoodie<br />Proof of Ink <ExternalLinkIcon
											boxSize={{ base: 3, md: 4 }}
											color={colorAlt}
											_groupHover={{
												color: BITCOIN_ORANGE
											}}
										/><br /><span style={{ color: colorAlt }}>(Made in USA)</span>
									</Text>
								</Link>

								

								<Link
									flex="1 1 33.33%"
									role="group"
									color={color}
									fontWeight="semibold"
									href="https://proofofink.com/product/timechain-calendar-genesis-shirt"
									display="flex"
									flexDirection="column"
									alignItems="center"
									isExternal
									_hover={{
										color: BITCOIN_ORANGE,
									}}
								>
									<Box
										bg={colorAlt2}
										borderRadius={8}
										p="2px"
										mb={1}
										_groupHover={{
											bg: BITCOIN_ORANGE
										}}
									>
										<Image
											src="/shop/POI-genesis-block-tshirt.jpg"
											boxSize={shirtImageBoxSize}
											maxW={shirtImageMaxW}
											objectFit="contain"
											borderRadius={8}
										/>
									</Box>
									
									<Text fontSize="sm" textAlign="center">
										Genesis Block T-shirt<br />Proof of Ink <ExternalLinkIcon
											boxSize={{ base: 3, md: 4 }}
											color={colorAlt}
											_groupHover={{
												color: BITCOIN_ORANGE
											}}
										/><br /><span style={{ color: colorAlt }}>(Made in USA)</span>
									</Text>
								</Link>
							</Flex>

							<Flex
								w="100%"
								gap={8}
								direction={responsiveDirection}
								align="center"
								justify="center"
							>
								<Link
									role="group"
									color={color}
									fontWeight="semibold"
									href="https://tortch.co/product/timechain-calendar-momento"
									display="flex"
									flexDirection="column"
									alignItems="center"
									isExternal
									_hover={{
										color: BITCOIN_ORANGE,
									}}
								>
									<Box
										bg={colorAlt2}
										borderRadius={8}
										p="2px"
										mb={1}
										_groupHover={{
											bg: BITCOIN_ORANGE
										}}
									>
										<Image
											src="/shop/momento-front.jpeg"
											boxSize={squareImageBoxSize}
											borderRadius={8}
										/>
									</Box>

									<Text fontSize="sm" textAlign="center">
										Custom Momento <br />Tortch.co <ExternalLinkIcon
											boxSize={{ base: 3, md: 4 }}
											color={colorAlt}
											_groupHover={{
												color: BITCOIN_ORANGE
											}}
										/><br /><span style={{ color: colorAlt }}>(Made in USA)</span>
									</Text>
								</Link>

								<Link
									role="group"
									color={color}
									fontWeight="semibold"
									href="https://theorangehabit.com/products/timechain-calendar-acrylic-wall-clock"
									display="flex"
									flexDirection="column"
									alignItems="center"
									isExternal
									_hover={{
										color: BITCOIN_ORANGE,
									}}
								>
									<Box
										bg={colorAlt2}
										borderRadius={8}
										p="2px"
										mb={1}
										_groupHover={{
											bg: BITCOIN_ORANGE
										}}
									>
										<Image
											src="/shop/Wall-Clock.jpg"
											boxSize={squareImageBoxSize}
											borderRadius={8}
										/>
									</Box>

									<Text fontSize="sm" textAlign="center">
										Wall Clock<br />The Orange Habit <ExternalLinkIcon
											boxSize={{ base: 3, md: 4 }}
											color={colorAlt}
											_groupHover={{
												color: BITCOIN_ORANGE
											}}
										/><br /><span style={{ color: colorAlt }}>(Made in USA)</span>
									</Text>
								</Link>

								<Link
									role="group"
									color={color}
									fontWeight="semibold"
									href="https://btcpins.com/product/timechain-calendar-pin/"
									display="flex"
									flexDirection="column"
									alignItems="center"
									isExternal
									_hover={{
										color: BITCOIN_ORANGE,
									}}
								>
									<Box
										bg={colorAlt2}
										borderRadius={8}
										p="2px"
										mb={1}
										_groupHover={{
											bg: BITCOIN_ORANGE
										}}
									>
										<Image
											src="/shop/BtcPins-TC.jpg"
											boxSize={squareImageBoxSize}
											borderRadius={8}
										/>
									</Box>

									<Text fontSize="sm" textAlign="center">
										Timechain Calendar Pin<br />BtcPins <ExternalLinkIcon
											boxSize={{ base: 3, md: 4 }}
											color={colorAlt}
											_groupHover={{
												color: BITCOIN_ORANGE
											}}
										/><br /><span style={{ color: colorAlt }}>(Made in USA)</span>
									</Text>
								</Link>
							</Flex>
						</Flex>
					</ModalBody>

					<ModalFooter mt={2}>
						<Flex
							w="100%"
							justify="flex-end"
							align="flex-end"
							gap={2}
						>
							<Button
								onClick={onClose}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								textTransform="uppercase"
								_active={interactionStyles}
								_hover={interactionStyles}
							>
								<FormattedMessage id="shared.close_button_label" />
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
